<template>
  <sub-page
      :title="$t('Statistics')"
      icon="mdi-account-multiple"
      v-model="$store.state.settings.pageData"
  >
    <h1>Achievements students statistics</h1>
    here will general students statistics or achivements statistics
  </sub-page>
</template>

<script>
export default {
  name: "achievementsList",
  computed : {
  }
}
</script>

<style scoped>

</style>